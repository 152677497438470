import ahoy from 'ahoy.js'
import UIkit from 'uikit'

ahoy.trackClicks('a, button, input[type=submit]')
ahoy.trackSubmits('form')

document.addEventListener('turbo:load', () => {
  ahoy.trackView()

  // Use UIkit's scrollspy to track impressions
  UIkit.scrollspy('*[data-analytics-type]:not([uk-scrollspy])', { hidden: false })
})

UIkit.util.on(document, 'inview', '*[data-analytics-type]', e => {
  const { analyticsType, analyticsId = e.target.href } = e.target.dataset

  ahoy.track('impression', {
    url: window.location.href,
    type: analyticsType,
    id: analyticsId
  })
})

function onPrint () {
  ahoy.track('print', { url: window.location.href })
}

// IE
if (window.matchMedia) {
  const mediaQueryList = window.matchMedia('print')
  mediaQueryList.addListener(function (mql) {
    if (!mql.matches) onPrint()
  })
}

// Everyone else
window.addEventListener('afterprint', onPrint)
